import React, {useEffect, useState} from 'react';
import {GeoVictoriaMigrationLogRowSchema} from "../GeoVictoriaToBukMigration";
import {Card, Divider, Form, Input, Modal} from "antd";
import JsonView from "@uiw/react-json-view";
import { githubLightTheme } from '@uiw/react-json-view/githubLight';

interface GeoVictoriaMigrationDetailModalProps {
    selectedLog: GeoVictoriaMigrationLogRowSchema;
    handleGeoVictoriaMigrationDetailModalCancel: () => void;
    isGeoVictoriaMigrationDetailModalOpen: boolean;
}

const COLLAPSE_NUMBER = 2;

function GeoVictoriaMigrationDetailModal({ isGeoVictoriaMigrationDetailModalOpen, selectedLog, handleGeoVictoriaMigrationDetailModalCancel }: GeoVictoriaMigrationDetailModalProps) {
    const [form] = Form.useForm();
    const [collapse, setCollapse] = useState(COLLAPSE_NUMBER);

    useEffect(() => {
        if(isGeoVictoriaMigrationDetailModalOpen) {
            form.resetFields();
        }
    }, [isGeoVictoriaMigrationDetailModalOpen]);

    return (
        <Modal
            title={`Detalles de migración [ID: ${selectedLog.id}]`}
            open={isGeoVictoriaMigrationDetailModalOpen}
            footer={false}
            onCancel={handleGeoVictoriaMigrationDetailModalCancel}
            maskClosable={false}
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form layout="vertical">
                <Form.Item
                    label="Tipo de proceso"
                >
                    <Input readOnly value={ selectedLog.key === 'GEO_VICTORIA_MIGRATION_TO_BUK_PROCESS' ?  'MIGRACIÓN BÁSICA' : selectedLog.key }/>
                </Form.Item>


                <Form.Item
                    name="params"
                    label="Parámetros de entrada"
                >
                    <Card>
                        <JsonView value={selectedLog.params} style={githubLightTheme} collapsed={collapse} displayDataTypes={false}/>
                    </Card>
                </Form.Item>


                <Form.Item
                    name="payload"
                    label="Respuesta"
                >
                    <Card>
                        <JsonView value={selectedLog.payload} style={githubLightTheme} collapsed={1} displayDataTypes={false} />
                    </Card>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default GeoVictoriaMigrationDetailModal;
