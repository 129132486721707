import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    Button,
    Card,
    DatePicker,
    Dropdown,
    Flex,
    Form,
    GetProp, MenuProps,
    message,
    Select,
    Table,
    TablePaginationConfig, TableProps
} from "antd";
import {MoreOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import moment from "moment";
import {TitaskIntegrationService} from "../../../services/TitaskIntegrationService";
import {ServerSideDataSchema} from "../../../models/ServerSideDataSchema";
import {ApiErrorData} from "../../../models/ApiResponse";
import dayjs from 'dayjs';
import NewGeoVictoriaMigrationModal from "./components/NewGeoVictoriaMigrationModal";
import GeoVictoriaMigrationDetailModal from "./components/GeoVictoriaMigrationDetailModal";

export interface GeoVictoriaMigrationLogRowSchema {
    id: number;
    key: string;
    type: string;
    status: string;
    createdAt: Date;
    finishedAt: Date | null;
    params: any;
    payload: any;
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PAGE_SIZE = 10;
const { RangePicker } = DatePicker;


function GeoVictoriaToBukMigration() {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<GeoVictoriaMigrationLogRowSchema[]>([]);
    const [startDateFilter, setStartDateFilter] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'));
    const [endDateFilter, setEndDateFilter] = useState(moment().format('YYYY-MM-DD'));
    const [statusFilter, setStatusFilter] = useState<'MANUAL' | 'AUTOMATIC' | undefined>();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
    });
    const [newGeoVictoriaMigrationLoading, setNewGeoVictoriaMigrationLoading] = useState(false);
    const [isNewGeoVictoriaMigrationModalOpen, setIsNewGeoVictoriaMigrationModalOpen] = useState(false);
    const [isGeoVictoriaMigrationDetailModalOpen, setIsGeoVictoriaMigrationDetailModalOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState<GeoVictoriaMigrationLogRowSchema | undefined>();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, startDateFilter, endDateFilter, statusFilter]);

    const init = async () => {
        setLoading(true);

        await fetchData();

        setLoading(false);
    }

    const fetchData = async (currentPage?: number) => {
        setLoading(true);


        const getGeoVictoriaMigrationTriggerLogResponse = await TitaskIntegrationService.getGeoVictoriaMigrationTriggerLog({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            startDate: startDateFilter,
            endDate: endDateFilter,
            type: statusFilter
        });

        if(getGeoVictoriaMigrationTriggerLogResponse.success) {
            const data = getGeoVictoriaMigrationTriggerLogResponse.data as ServerSideDataSchema<GeoVictoriaMigrationLogRowSchema>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = getGeoVictoriaMigrationTriggerLogResponse.data as ApiErrorData;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setLoading(false);
    }

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const onFinish = (values: any) => {
        setStartDateFilter(values.range[0].format('YYYY-MM-DD'));
        setEndDateFilter(values.range[1].format('YYYY-MM-DD'));

        if(values.type) {
            setStatusFilter(values.type);
        }else {
            setStatusFilter(undefined);
        }

        // setTableParams({
        //     ...tableParams,
        //     pagination: {
        //         ...tableParams.pagination,
        //         current: 1
        //     }
        // })

        console.log("finish", values)
    };

    const getDropdownMenu = (row: GeoVictoriaMigrationLogRowSchema): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span onClick={() => { showGeoVictoriaMigrationDetail(row) }}><SearchOutlined /> Ver detalles</span>,
            },
        ];
    }

    const showNewGeoVictoriaMigrationModal = () => {
        if(!loading) {
            setIsNewGeoVictoriaMigrationModalOpen(true);
        }
    }

    const handleNewGeoVictoriaMigrationModalOk = async (startDate: string, endDate: string, frequency: 'diaria' | 'mensual') => {
        if(!newGeoVictoriaMigrationLoading) {
            setNewGeoVictoriaMigrationLoading(true);

            messageApi.open({
                key: 'submit-migration',
                type: 'loading',
                content: 'Generando migración, esto puede tardar varios minutos...',
                duration: 0
            });

            const executeMigrationResponse = await TitaskIntegrationService.executeGeoVictoriaToBukManualMigration({
                startDate,
                endDate,
                frequency
            });

            if(executeMigrationResponse.success) {
                setNewGeoVictoriaMigrationLoading(false);
                messageApi.destroy();
                messageApi.success('Se realizó la migración satisfactoriamente.', 3.5);
                setIsNewGeoVictoriaMigrationModalOpen(false);
            }else {
                const error = executeMigrationResponse.data as ApiErrorData;
                messageApi.destroy();
                messageApi.error(error.message as string || 'Hubo un error al intentar realizar la migración, por favor inténtalo nuevamente.', 3.5);
                setLoading(false);
            }

            setNewGeoVictoriaMigrationLoading(false);
            await fetchData(1);
        }
    }

    const handleNewGeoVictoriaMigrationModalCancel = () => {
        if(!newGeoVictoriaMigrationLoading) {
            setIsNewGeoVictoriaMigrationModalOpen(false);
        }
    }

    const showGeoVictoriaMigrationDetail = (row: GeoVictoriaMigrationLogRowSchema) => {
        setSelectedLog(row);
        setIsGeoVictoriaMigrationDetailModalOpen(true);
    }

    const handleGeoVictoriaMigrationDetailModalCancel = () => {
        setIsGeoVictoriaMigrationDetailModalOpen(false);
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                { title: 'Geovictoria' },
                { title: 'Migración a Buk' },
            ]}>
                <Card>
                    <Flex justify="space-between">
                        <Form form={form} layout="inline" onFinish={onFinish}>
                            <Form.Item
                                name="range"
                                label="Filtro por fechas"
                            >
                                <RangePicker allowClear={false}  defaultValue={[ dayjs().add(-30, 'day'), dayjs() ]}/>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Tipo"
                            >
                                <Select style={{ width: '125px' }} placeholder="-TODOS-" allowClear disabled={loading}>
                                    <Select.Option key="MANUAL" value="MANUAL">Manual</Select.Option>
                                    <Select.Option key="AUTOMATIC" value="AUTOMATIC">Automático</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Filtrar
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>

                        <Button style={{ float: 'right' }} type="primary" loading={loading} onClick={() => { showNewGeoVictoriaMigrationModal(); }}><PlusOutlined /> Migración Manual</Button>
                    </Flex>
                </Card>

                <Card style={{ marginTop: '12px' }}>
                    <Table<GeoVictoriaMigrationLogRowSchema> dataSource={dataSource} bordered loading={loading} size="small" scroll={{ x: 1300 }} pagination={tableParams.pagination} rowKey={(record) => record.id} onChange={handleTableChange}>
                        <Column title="ID" dataIndex="id" key="id" />
                        <Column title="Tipo de proceso" dataIndex="key" key="key" render={(key: string) => (
                            <span>{key === 'GEO_VICTORIA_MIGRATION_TO_BUK_PROCESS' ?  'MIGRACIÓN BÁSICA' : key}</span>
                        )}/>
                        <Column title="Modalidad" dataIndex="type" key="type" render={(type: string) => (
                            <span>{type === 'MANUAL' ? 'Manual' : 'Automático'}</span>
                        )}/>
                        <Column title="Status" dataIndex="status" key="status" render={(status: string) => (
                            <span>{status === 'OK' ? 'Finalizado' : (status === 'PENDING' ? 'Pendiente' : 'Finalizado Parcialmente')}</span>
                        )}/>
                        <Column
                            width={160}
                            align="center"
                            title="Fecha de ocurrencia"
                            dataIndex="createdAt"
                            key="createdAtFormatted"
                            render={(createdAt: Date) => (
                                <span>{moment(createdAt).format('DD/MM/YYYY h:mm A')}</span>
                            )}
                        />
                        <Column
                            width={160}
                            align="center"
                            title="Fecha de finalización"
                            dataIndex="finishedAt"
                            key="finishedAtFormatted"
                            render={(finishedAt: Date) => (
                                <span>{finishedAt ? moment(finishedAt).format('DD/MM/YYYY h:mm A') : 'Sin finalizar'}</span>
                            )}
                        />
                        <Column width={120} align="center" title="" key="actions" render={(row) => (
                            <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                <Button size="small"><MoreOutlined /></Button>
                            </Dropdown>
                        )} />
                    </Table>
                </Card>

                {/* Modals */}
                <NewGeoVictoriaMigrationModal isNewGeoVictoriaMigrationModalOpen={isNewGeoVictoriaMigrationModalOpen} handleNewGeoVictoriaMigrationModalOk={handleNewGeoVictoriaMigrationModalOk} handleNewGeoVictoriaMigrationModalCancel={handleNewGeoVictoriaMigrationModalCancel} loading={newGeoVictoriaMigrationLoading}/>

                {
                    selectedLog && (
                        <GeoVictoriaMigrationDetailModal selectedLog={selectedLog} isGeoVictoriaMigrationDetailModalOpen={isGeoVictoriaMigrationDetailModalOpen} handleGeoVictoriaMigrationDetailModalCancel={handleGeoVictoriaMigrationDetailModalCancel} />
                    )
                }
            </Layout>
        </>
    );
}

export default GeoVictoriaToBukMigration;
