import React, {useEffect} from 'react';
import {DatePicker, Divider, Form, Modal, Select} from "antd";

interface NewGeoVictoriaMigrationModalProps {
    isNewGeoVictoriaMigrationModalOpen: boolean,
    handleNewGeoVictoriaMigrationModalOk: (startDate: string, endDate: string, frequency: 'diaria' | 'mensual') => void;
    handleNewGeoVictoriaMigrationModalCancel: () => void;
    loading: boolean;
}

const { RangePicker } = DatePicker;

function NewGeoVictoriaMigrationModal({ isNewGeoVictoriaMigrationModalOpen, handleNewGeoVictoriaMigrationModalOk, handleNewGeoVictoriaMigrationModalCancel, loading }: NewGeoVictoriaMigrationModalProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        if(isNewGeoVictoriaMigrationModalOpen) {
            form.setFieldValue('range', null);
            form.setFieldValue('frequency', null);
        }
    }, [isNewGeoVictoriaMigrationModalOpen])

    const onFinish = (values: any) => {
        handleNewGeoVictoriaMigrationModalOk(values.range[0].format('YYYY-MM-DD'), values.range[1].format('YYYY-MM-DD'), values.frequency);
    };

    const validateDateRange = (_: any, value: any) => {
        if (!value || value.length < 2) {
            return Promise.resolve();
        }
        const [start, end] = value;
        const frequency = form.getFieldValue('frequency');
        const currentYear = new Date().getFullYear();

        if (frequency === 'diaria') {
            if (start.year() !== currentYear || end.year() !== currentYear) {
                return Promise.reject(new Error('El año del rango debe ser el actual'));
            }
            if (start.month() !== end.month() || start.year() !== end.year()) {
                return Promise.reject(new Error('El mes y año del rango inicial y final deben ser los mismos'));
            }
        }

        if (end.diff(start, 'days') > 31) {
            return Promise.reject(new Error('El rango no puede superar los 31 días'));
        }
        return Promise.resolve();
    };

    const handleFrequencyChange = (value: 'diaria' | 'mensual') => {
        form.validateFields(['range']);
    };

    return (
        <Modal
            title="Generar migración manual"
            open={isNewGeoVictoriaMigrationModalOpen}
            onOk={() => { form.submit(); }}
            onCancel={handleNewGeoVictoriaMigrationModalCancel}
            okText="Generar migración"
            maskClosable={false}
            confirmLoading={loading}
            destroyOnClose
        >
            <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="range"
                    label="Fechas"
                    rules={[
                        { required: true, message: 'El campo es requerido' },
                        { validator: validateDateRange },
                    ]}
                >
                    <RangePicker allowClear={false} style={{ width: '100%' }} format="DD/MM/YYYY"/>
                </Form.Item>
                <Form.Item
                    name="frequency"
                    label="Tipo de pago"
                    rules={[
                        { required: true, message: 'El campo es requerido' },
                    ]}
                >
                    <Select style={{ width: '100%' }} placeholder="-SELECCIONE-" allowClear onChange={handleFrequencyChange}>
                        <Select.Option key="diaria" value="diaria">Por día</Select.Option>
                        <Select.Option key="mensual" value="mensual">Por mes</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewGeoVictoriaMigrationModal;
