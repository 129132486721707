import React, {ReactNode, useState} from 'react';
import {
    Avatar, Button,
    ConfigProvider, Drawer,
    Dropdown,
    Flex,
    Layout as AntdLayout,
    Menu,
    MenuProps,
    Space,
} from 'antd';
import {
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import AuthAction from "../../redux/actions/authActions/Actions";
import {useDispatch, useSelector} from "react-redux";
import {User} from "../../models/User";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {AuthService} from "../../services/AuthService";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import dashboardMenu from "../../config/DashboardMenu";
import {MenuStore} from "../../redux/reducers/MenuReducer";
import MenuAction from "../../redux/actions/menuActions/Actions";
import Breadcrumb, {ItemType} from "antd/es/breadcrumb/Breadcrumb";
import esES from 'antd/locale/es_ES';
import {useThemeLayoutConfig} from "../../config/ThemeDashboardLayoutConfig";
import Title from "antd/es/typography/Title";
import { useMediaQuery } from 'react-responsive';
import ConfigSettings from "./ConfigSettings";
import {DashboardConfig} from "../../models/DashboardConfig";

import './Layout.scss';
import DashboardConfigAction from "../../redux/actions/dashboardConfigActions/Action";

interface LayoutProps {
    children: ReactNode;
    loading?: boolean;
    title?: string | React.ReactNode;
    breadcrumb?: ItemType[];
}

const { Header, Content, Sider } = AntdLayout;

function Layout({ children, loading = false, title = '', breadcrumb = [] }: LayoutProps) {
    const customConfig = useThemeLayoutConfig();
    const dashboardConfig: DashboardConfig = useSelector((state: any) => state.dashboardConfig);
    const dispatch = useDispatch();
    const authUser: User = useSelector((state: any) => state.auth);
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
    const { selectedKeys, openKeys }: MenuStore = useSelector((state: any) => state.menu);
    const location = useLocation();
    const [localLoading, setLocalLoading] = useState(false);
    const { routes: menus } = dashboardMenu(authUser);
    const [isToggled, setToggled] = useState(false);
    const toggleTrueFalse = () => setToggled(!isToggled);
    const onClose = () => {
        setToggled(false);
    };

    const logout = async () => {
        setLocalLoading(true);

        if (authUser.token) {
            await AuthService.logout();
        }

        dispatch(AuthAction.signOut({}));
        dispatch(DashboardConfigAction.remove());
        setLocalLoading(false);
    }

    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => { logout(); }}><LogoutOutlined /> Cerrar sesión</a>,
            key: '1',
        },
    ];

    return (
        <ConfigProvider locale={esES} theme={customConfig.theme}>
            <LoadingScreen loading={(loading || localLoading)}/>
            <AntdLayout style={{ minHeight: '100vh' }}>
                {
                    !isDesktop && (
                        <Drawer
                            placement="left"
                            onClose={onClose}
                            closable={false}
                            open={isToggled}
                            bodyStyle={{ backgroundColor: customConfig.theme.token?.colorPrimary || '#FFF', padding: "0" }}
                            width="80%"
                        >
                            <Flex  gap="middle" justify="center" align="center" style={{ cursor: "pointer" }}>
                                <img
                                    className="logo"
                                    src={dashboardConfig.logoUrl}
                                    style={{ height: `${dashboardConfig.logoHeight + 4}px`, padding: '10px' }}
                                />
                            </Flex>
                            <Menu
                                mode="inline"
                                style={{ height: '100%', borderRight: 0, padding: '4px 4px' }}
                                selectedKeys={selectedKeys}
                                openKeys={openKeys}
                                forceSubMenuRender
                                onOpenChange={(openKeysUpdated) => {
                                    dispatch(MenuAction.update({ openKeys: openKeysUpdated }));
                                }}
                            >
                                {
                                    menus.map((menu) => (
                                        menu.children ? (
                                            <Menu.SubMenu title={menu.label} key={menu.key} icon={menu.icon}>
                                                {
                                                    menu.children.map((submenuItem: any) => (
                                                        <Menu.Item key={submenuItem.key} icon={submenuItem.icon}>
                                                            {
                                                                submenuItem.path ? (<Link to={submenuItem.path}>{submenuItem.label}</Link>) : <span>{submenuItem.label}</span>
                                                            }
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </Menu.SubMenu>
                                        ) : (
                                            <Menu.Item key={menu.key} icon={menu.icon}>
                                                {
                                                    menu.path ? (<Link to={menu.path}>{menu.label}</Link>) : <span>{menu.label}</span>
                                                }
                                            </Menu.Item>
                                        )
                                    ))
                                }
                            </Menu>
                        </Drawer>
                    )
                }
                <Header className="activation-header" style={{ backgroundColor: customConfig.theme.token?.colorPrimary }}>
                    <Flex gap="middle" justify="space-between" style={{ width: '100%' }}>
                        { !isDesktop && React.createElement(
                            isToggled ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger-menu-layout",
                                onClick: toggleTrueFalse,
                            }
                        )}
                        <Flex gap="middle" justify="space-between" align="center" style={{ cursor: "pointer" }}>
                            <img
                                className="logo"
                                src={dashboardConfig.logoUrl}
                                style={{ height: `${dashboardConfig.logoHeight}px` }}
                            />
                        </Flex>
                        <Space direction="vertical">
                            <Space wrap>
                                <Dropdown
                                    menu={{ items }}
                                    trigger={["click"]}
                                    placement="bottomLeft"
                                    arrow
                                >
                                    <div onClick={e => e.preventDefault()}>
                                        <Space size={16} wrap>
                                            <Avatar style={{ backgroundColor: customConfig.customValues.secondaryColor || '#cfe8fc', color: customConfig.theme.token?.colorPrimary || '#0077cc', cursor: 'pointer' }}><span style={{ textTransform: 'uppercase' }}>{ authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }</span></Avatar>
                                        </Space>
                                    </div>
                                </Dropdown>
                            </Space>
                        </Space>
                    </Flex>
                </Header>
                <AntdLayout>
                    {
                        isDesktop && (
                            <Sider
                                width={320}
                                style={{ background: customConfig.theme.token?.colorBgContainer }}
                                // collapsedWidth={0}
                                // collapsed={false}
                                collapsible
                            >
                                <Menu
                                    mode="inline"
                                    style={{ height: '100%', borderRight: 0, padding: '4px 4px' }}
                                    selectedKeys={selectedKeys}
                                    openKeys={openKeys}
                                    forceSubMenuRender
                                    onOpenChange={(openKeysUpdated) => {
                                        dispatch(MenuAction.update({ openKeys: openKeysUpdated }));
                                    }}
                                >
                                    {
                                        menus.map((menu) => (
                                            menu.children ? (
                                                <Menu.SubMenu title={menu.label} key={menu.key} icon={menu.icon}>
                                                    {
                                                        menu.children.map((submenuItem: any) => (
                                                            <Menu.Item key={submenuItem.key} icon={submenuItem.icon}>
                                                                {
                                                                    submenuItem.path ? (<Link to={submenuItem.path}>{submenuItem.label}</Link>) : <span>{submenuItem.label}</span>
                                                                }
                                                            </Menu.Item>
                                                        ))
                                                    }
                                                </Menu.SubMenu>
                                            ) : (
                                                <Menu.Item key={menu.key} icon={menu.icon}>
                                                    {
                                                        menu.path ? (<Link to={menu.path}>{menu.label}</Link>) : <span>{menu.label}</span>
                                                    }
                                                </Menu.Item>
                                            )
                                        ))
                                    }
                                </Menu>
                            </Sider>
                        )
                    }
                    <AntdLayout style={{ padding: '18px 24px' }}>
                        {
                            (breadcrumb.length > 0 || title) && (
                                <Flex gap="small" justify="flex-start" vertical className="dashboard-layout-header-container">
                                    {
                                        breadcrumb.length > 0 && (
                                            <Flex gap="middle" justify="flex-start" className="dashboard-layout-breadcrumb">
                                                <Breadcrumb
                                                    separator=">"
                                                    items={breadcrumb}
                                                />
                                            </Flex>
                                        )
                                    }
                                    {
                                        title && (
                                            <Flex gap="middle" justify="flex-start" className="dashboard-layout-title">
                                                <Title level={3} style={{ textTransform: 'uppercase', marginBottom: '0' }}>{title}</Title>
                                            </Flex>
                                        )
                                    }
                                    {/*<Divider style={{ marginTop: '0', marginBottom: '0' }}/>*/}
                                </Flex>
                            )
                        }
                        {children}
                    </AntdLayout>
                </AntdLayout>
                <ConfigSettings/>
            </AntdLayout>
        </ConfigProvider>
    );
}

export default Layout;
